import React, { useRef, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Link as RouterLink } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { api } from "../core";
import Authors from "./Authors";

const RecEditable = ({ rec, index, id, handleOpen, moveRec, disabled, setDisabled, addRec, removeRec }) => {
  const handleRef = useRef(null);
  const previewRef = useRef(null);

  const handleRemove = async mediaId => {
    setDisabled(true);
    const result = await api.removeRec(mediaId);
    if (result) {
      removeRec(index);
    }
    setDisabled(false);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: rec.typeId,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!previewRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRec(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item, monitor) {
      setDisabled(true);
      api.moveRec(item.id, item.index)
        .then(result => {
          setDisabled(false);
        });
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: rec.typeId,
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(handleRef);
  drop(preview(previewRef));

  return (
    <Box component="li" sx={{ opacity, mb: 3, whiteSpace: "pre-line" }}>
      <Box ref={handleRef} sx={{ position: "absolute", left: 0, zIndex: 1, display: "inline-block", textAlign: "center", width: 54 }}>
        <DragHandleIcon color={disabled ? "disabled" : "primary"} sx={{ display: "inline-block" }} />
      </Box>
      <Box ref={previewRef} data-handler-id={handlerId}>
        <Link href={rec.mediaUrl} target="_blank" rel="noopener">{rec.title}</Link>
        <Authors authors={rec.authors} />
        <span> — {rec.description}</span>
        <Box>
          <Button onClick={() => handleOpen(rec.typeId, rec, index, addRec)} disabled={disabled} sx={{ ml: -1 }}>Edit</Button>
          <Button onClick={() => handleRemove(rec.mediaId)} disabled={disabled}>Remove</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RecEditable;