import React from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Authors from "./Authors";

const Rec = ({ rec }) => {
  return (
    <Box component="li" sx={{ mb: 2, whiteSpace: "pre-line" }}>
      <Link href={rec.mediaUrl} target="_blank" rel="noopener">{rec.title}</Link>
      <Authors authors={rec.authors} />
      <span> — {rec.description}</span>
    </Box>
  );
};

export default Rec;