import React from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Authors = ({ authors }) => {
  if (!authors || authors.length === 0) {
    return null;
  }

  return (
    <>
      <span> by </span>
      {authors.map((author, index) => {
        const separator = index < authors.length - 2 ? ", " : index == authors.length - 2 ? " and " : "";
        return (
          <Box component="span" key={index}>
            {author.username ? (
              <Link component={RouterLink} to={"/" + author.username}>{author.name}</Link>
            ) : (
              <span>{author.name}</span>
            )}
            {separator}
          </Box>
        );
      })}
    </>
  );
};

export default Authors;